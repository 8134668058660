import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
	Drawer,
	Box,
	List,
	ListItemText,
	ListItemButton,
	Collapse,
	IconButton,
	Typography,
	Divider,
} from "@mui/material";
import { usePageContext } from "../context/PageContext";
import { useAuth } from "../context/AuthContext";
import { getAllPages } from "../services/apiServices";
import { ExpandLess, ExpandMore, Logout } from "@mui/icons-material";
import UserAvatar from "./UserAvatar";

const appBarHeight = 64;

interface Page {
	id: string;
	title: string;
	children: Page[];
}

const Sidebar: React.FC = () => {
	const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});
	const [pages, setPages] = useState<Page[]>([]);
	const { selectedPageId, setSelectedPageId } = usePageContext();
	const { isAuthenticated, userName } = useAuth();
	const [selectedIndex, setSelectedIndex] = React.useState(1);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleToggle = (pageId: string) => {
		setOpenItems((prevState) => ({
			...prevState,
			[pageId]: !prevState[pageId],
		}));
	};

	const handleLogout = () => {
		window.close();
	};

	useEffect(() => {
		const fetchPages = async () => {
			try {
				const pageListArray = await getAllPages(isAuthenticated);
				if (pageListArray.length > 1) {
					const pageArray = [
						...pageListArray[1].children,
						...pageListArray[0].children,
					];
					setPages(pageArray);
				} else {
					setPages(pageListArray[0].children);
				}
			} catch (error) {
				console.error("Error fetching pages:", error);
			}
		};
		fetchPages();
	}, [isAuthenticated]);

	const renderPages = (pages: Page[], indent = 0) => (
		<List sx={{ flexGrow: 1 }}>
			{pages.map((page) => (
				<React.Fragment key={page.id}>
					<ListItemButton
						onClick={() => {
							const hasChildren = page.children && page.children.length > 0;
							!hasChildren && setSelectedPageId(page.id);
							setSelectedIndex(Number.parseInt(page.id));
						}}
						sx={{ ml: indent }}
						selected={selectedIndex === Number.parseInt(page.id)}
					>
						<ListItemText
							primary={page.title}
							onClick={() => {
								page.children &&
									page.children.length > 0 &&
									handleToggle(page.id);
							}}
						/>
						{page.children && page.children.length > 0 && (
							<IconButton onClick={() => handleToggle(page.id)} edge="start">
								{openItems[page.id] ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
						)}
					</ListItemButton>
					{page.children && page.children.length > 0 && (
						<Collapse in={openItems[page.id]} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{renderPages(page.children, indent + 2)}
							</List>
						</Collapse>
					)}
				</React.Fragment>
			))}
		</List>
	);

	if (isMobile) {
		return <></>;
	}

	return (
		<Drawer
			variant="permanent"
			open={true}
			sx={{
				width: { xl: "15%", sm: "30%" },
				flexShrink: 0,
				"& .MuiDrawer-paper": {
					width: { xl: "15%", sm: "30%" },
					marginTop: `${appBarHeight}px`,
					display: "flex",
					flexDirection: "column",
				},
				"@media print": {
					display: "none",
				},
			}}
		>
			<Box
				sx={{
					overflowY: "auto",
					overflowX: "hidden",
					display: "flex",
					flexDirection: "column",
					height: { xl: "85vh", lg: "85vh", md: "calc(100vh - 52%)" },
				}}
			>
				{renderPages(pages)}
			</Box>

			{userName && (
				<>
					<Divider
						sx={{
							mb: 2,
						}}
					/>

					<Box
						sx={{
							ml: 2,
							display: "flex",
							alignItems: "center",
						}}
					>
						<div style={{ display: "flex", alignItems: "center" }}>
							<UserAvatar />
							<Typography variant="body1" sx={{ ml: 1 }}>
								{userName}
							</Typography>
						</div>

						<Box sx={{ flexGrow: 1 }} />
						<IconButton color="error" onClick={handleLogout} sx={{ mt: 1 }}>
							<Logout />
						</IconButton>
					</Box>
				</>
			)}
		</Drawer>
	);
};

export default Sidebar;
