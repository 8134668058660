import { AES, enc } from "crypto-js";

const TOKEN_ENCRYPTION_KEY =
  process.env.REACT_APP_ROOSTER_SUPPORT_ENCRYPTION_KEY;

export const decryptString = (
  str: string,
  secret: string = TOKEN_ENCRYPTION_KEY || ""
) => {
  const decryptedBytes = AES.decrypt(str, secret);
  const decryptedString = decryptedBytes.toString(enc.Utf8);
  return decryptedString;
};

export const decryptObject = (
  str: string,
  secret: string = TOKEN_ENCRYPTION_KEY || ""
) => {
  const decryptedStringifiedObject = decryptString(str, secret);
  return JSON.parse(decryptedStringifiedObject);
};
