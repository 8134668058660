import React from "react";
import { Avatar } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { usePageContext } from "../context/PageContext";

const UserAvatar: React.FC = () => {
  const { userAvatar } = usePageContext();
  const { userName } = useAuth();
  return (
    <Avatar src={userAvatar || undefined} alt={userName || undefined}>
      {!userAvatar && userName?.charAt(0)}
    </Avatar>
  );
};

export default UserAvatar;
