export function isWithinCurrentHour(unixTimestamp: number): boolean {
  const timestampInMillis = unixTimestamp; // Convert Unix timestamp to milliseconds

  const currentDate = new Date();

  // Get the start of the current hour
  const startOfCurrentHour = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    0,
    0,
    0
  ).getTime();

  // Get the end of the current hour
  const endOfCurrentHour = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    currentDate.getHours(),
    59,
    59,
    999
  ).getTime();

  // Check if the given timestamp falls within the current hour range
  return (
    timestampInMillis >= startOfCurrentHour &&
    timestampInMillis <= endOfCurrentHour
  );
}
