import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
	AppBar,
	Toolbar,
	InputBase,
	IconButton,
	Box,
	Tooltip,
	Menu,
	Collapse,
} from "@mui/material";
import {
	Cancel as CancelIcon,
	ExpandLess,
	ExpandMore,
	HelpOutline,
	Lightbulb,
	Menu as MenuIcon,
} from "@mui/icons-material";
import { styled, alpha } from "@mui/material/styles";
import { usePageContext } from "../context/PageContext";
import { useAuth } from "../context/AuthContext";
import {
	Paper,
	List,
	ListItem,
	ListItemText,
	ListItemButton,
} from "@mui/material";
import { queryData, getAllPages } from "../services/apiServices";

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.25),
	"&:hover": {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: "auto",
	width: "auto",
}));

const ClearIconWrapper = styled(IconButton)(({ theme }) => ({
	position: "absolute",
	right: 0,
	top: "50%",
	transform: "translateY(-50%)",
	padding: theme.spacing(0.9),
	fontSize: "1rem",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	position: "relative",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(0)})`,
		paddingRight: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		border: `1px solid ${theme.palette.action.disabled}`,
		borderRadius: theme.shape.borderRadius,
		[theme.breakpoints.up("md")]: {
			width: "50ch",
		},
		"&:focus": {
			borderColor: theme.palette.primary.main,
		},
	},
}));

interface Page {
	id: string;
	title: string;
	children: Page[];
}

const Header: React.FC = () => {
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState<
		{ content: { id: string }; title: string; excerpt: string }[]
	>([]);
	const [showResults, setShowResults] = useState(false);
	const { setSelectedPageId } = usePageContext();
	const { isAuthenticated } = useAuth();
	const wrapperRef = useRef(null);
	const [pages, setPages] = useState<Page[]>([]);
	const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});
	const [selectedIndex, setSelectedIndex] = React.useState(1);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	// Update the value of the same variable in Sidebar component
	const appBarHeight = 64;
	const { headerLogo } = usePageContext();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(e.target.value);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleToggle = (pageId: string) => {
		setOpenItems((prevState) => ({
			...prevState,
			[pageId]: !prevState[pageId],
		}));
	};

	const renderPages = (pages: Page[], indent = 0) => (
		<List sx={{ flexGrow: 1 }}>
			{pages.map((page) => (
				<React.Fragment key={page.id}>
					<ListItemButton
						onClick={() => {
							const hasChildren = page.children && page.children.length > 0;
							!hasChildren && setSelectedPageId(page.id);
							setSelectedIndex(Number.parseInt(page.id));
							!hasChildren && handleClose();
						}}
						sx={{ ml: indent }}
						selected={selectedIndex === Number.parseInt(page.id)}
					>
						<ListItemText
							primary={page.title}
							onClick={() => {
								page.children &&
									page.children.length > 0 &&
									handleToggle(page.id);
							}}
						/>
						{page.children && page.children.length > 0 && (
							<IconButton onClick={() => handleToggle(page.id)} edge="start">
								{openItems[page.id] ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
						)}
					</ListItemButton>
					{page.children && page.children.length > 0 && (
						<Collapse in={openItems[page.id]} timeout="auto" unmountOnExit>
							<List component="div" disablePadding>
								{renderPages(page.children, indent + 2)}
							</List>
						</Collapse>
					)}
				</React.Fragment>
			))}
		</List>
	);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout | null = null;

		const fetchPages = async () => {
			try {
				const pageListArray = await getAllPages(isAuthenticated);
				if (pageListArray.length > 1) {
					const pageArray = [
						...pageListArray[1].children,
						...pageListArray[0].children,
					];
					setPages(pageArray);
				} else {
					setPages(pageListArray[0].children);
				}
			} catch (error) {
				console.error("Error fetching pages:", error);
			}
		};

		const performSearch = async () => {
			if (searchQuery.length > 2) {
				try {
					const response = await queryData(isAuthenticated, searchQuery);

					setSearchResults(response);
					setShowResults(true);
				} catch (error) {
					console.error("Error searching:", error);
					setSearchResults([]);
				}
			} else {
				setSearchResults([]);
			}
		};

		if (searchQuery.trim() !== "") {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
			timeoutId = setTimeout(performSearch, 500);
		}

		fetchPages();

		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
				timeoutId = null;
			}
		};
	}, [searchQuery, isAuthenticated]);

	const handleResultSelect = (pageId: string) => {
		setSearchQuery(""); // Clear the search input
		setSearchResults([]); // Clear search results
		setSelectedPageId(pageId); // Set the selected page ID in the context
	};

	// Handle click outside to close results
	const handleClickOutside = (event: MouseEvent) => {
		if (
			wrapperRef.current &&
			!(wrapperRef.current as any).contains(event.target)
		) {
			setShowResults(false); // Close search results when clicking outside
		}
	};

	/**
	 * Replace the @@@hl@@@ and @@@endhl@@@ markers in the excerpt
	 * with the <mark> HTML tag for highlighting.
	 */
	function highlightExcerpt(excerpt: string): string {
		return excerpt
			.replace(/@@@hl@@@/g, "<mark>")
			.replace(/@@@endhl@@@/g, "</mark>");
	}

	const handleClear = () => {
		setSearchQuery("");
		setShowResults(false);
	};

	const navigateToBrainstormSite = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
		window.open(
			"https://rooster.hellonext.co",
			"_blank",
			"noopener noreferrer",
		);
	};

	const navigateToSupportSite = (
		event: React.MouseEvent<HTMLButtonElement>,
	) => {
		event.preventDefault();
		window.open(
			"https://support.roosterinc.com",
			"_blank",
			"noopener noreferrer",
		);
	};

	// Attach the event listener to detect outside clicks
	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			setSearchQuery("");
			setShowResults(false);
		};
	}, []);

	return (
		<AppBar
			position="fixed"
			sx={{
				backgroundColor: "white",
				color: "black",
				zIndex: (theme) => theme.zIndex.drawer + 1,
				height: appBarHeight,
				"@media print": {
					display: "none",
				},
			}}
			variant="outlined"
			ref={wrapperRef}
			elevation={0}
		>
			<Toolbar>
				{isMobile ? (
					<img
						src={headerLogo ? headerLogo : "/Rooster Logo (small).png"}
						alt="Logo"
						style={{ width: "30px" }}
					/>
				) : (
					<img
						src={headerLogo ? headerLogo : "/rooster logo.png"}
						alt="Logo"
						style={{ width: "150px" }}
					/>
				)}
				{/* <img src={headerLogo ? headerLogo : "/rooster logo.png"} alt="Logo" /> */}
				<Box sx={{ flexGrow: 1 }} />
				{isAuthenticated && (
					<Tooltip title="Submit a Support Ticket">
						<IconButton sx={{ mr: 1 }} onClick={navigateToSupportSite}>
							<HelpOutline />
						</IconButton>
					</Tooltip>
				)}
				{isAuthenticated && (
					<Tooltip title="Submit a brainstorm">
						<IconButton sx={{ mr: 1 }} onClick={navigateToBrainstormSite}>
							<Lightbulb />
						</IconButton>
					</Tooltip>
				)}
				<Search>
					<StyledInputBase
						placeholder="Search..."
						inputProps={{ "aria-label": "search" }}
						value={searchQuery}
						onChange={handleSearchInputChange}
					/>

					{searchQuery && (
						<ClearIconWrapper onClick={handleClear}>
							<CancelIcon sx={{ fontSize: 20 }} />
						</ClearIconWrapper>
					)}

					{showResults && searchResults.length > 0 && (
						<Paper
							sx={{
								position: "absolute",
								top: "100%",
								left: 0,
								right: 0,
								zIndex: 1,
								overflowY: "auto",
								borderRadius: 0,
								height: "calc(100vh - 160px)",
							}}
						>
							<Box>
								<List>
									{searchResults.map((result) => {
										if (result.excerpt !== "") {
											return (
												<>
													<ListItem key={result.content.id} disablePadding>
														<ListItemButton
															onClick={() =>
																handleResultSelect(result.content.id)
															}
														>
															<ListItemText
																primary={
																	<span
																		dangerouslySetInnerHTML={{
																			__html: highlightExcerpt(result.title),
																		}}
																	/>
																}
																secondary={
																	<span
																		dangerouslySetInnerHTML={{
																			__html: highlightExcerpt(result.excerpt),
																		}}
																	/>
																}
															/>
														</ListItemButton>
													</ListItem>
												</>
											);
										}
									})}
								</List>
							</Box>
						</Paper>
					)}
				</Search>
				{isMobile && (
					<IconButton sx={{ ml: { xs: 6 } }} onClick={handleClick}>
						<MenuIcon />
					</IconButton>
				)}
			</Toolbar>
			{isMobile && (
				<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
					{renderPages(pages)}
				</Menu>
			)}
		</AppBar>
	);
};

export default Header;
