import axiosInstance from "../api/axiosInstance";

export async function getAllPages(isAuthenticated: boolean) {
  const section = isAuthenticated
    ? ["Rooster Support", "Restricted"]
    : ["Rooster Support"];
  const response = await axiosInstance.post("/api/pages", { section });
  return response.data;
}

export async function queryData(isAuthenticated: boolean, query: string) {
  const response = await axiosInstance.post("/api/search", {
    query,
    isAuthenticated,
  });
  return response.data;
}

export async function getContent(isAuthenticated: boolean, pageId: string) {
  try {
    const response = await axiosInstance.post("/api/get-page", {
      pageId,
    });
    const body = response.data.body;
    const title = response.data.title;
    return { body, title };
  } catch (error) {
    console.error("Error fetching page content:", error);
    return { body: "Error", title: "Unable to load content" };
  }
}

// Function to handle file download
export const downloadFile = async (downloadUrl: string) => {
  try {
    const response = await axiosInstance.post(
      "/api/download",
      { downloadUrl },
      {
        responseType: "blob",
      }
    );

    const fileUrl = new URL(`http://dummy.com${downloadUrl}`);
    const param = fileUrl.searchParams.get("preview") || "downloadfile";

    const decodedPreview = decodeURIComponent(param);
    const filename = decodedPreview.split("/").pop() || "downloaded-file";

    const blob = new Blob([response.data], { type: response.data.type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

// Function to handle PDF download
export const downloadPDF = async ( pageTitle: string, contentHtml: string) => {
  try {
    const response = await axiosInstance.post(
      "/api/getPDF",
      {  pageTitle,  pageContent:contentHtml },
      {
        responseType: "blob",
      }
    );

    const filename = `${pageTitle}.pdf`;

    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
};