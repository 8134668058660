export const defaultPageContent = `<div name="default-content" id="default-content">
         
          <p>We’re here to help you! Whether you’re looking for answers to
          common questions, troubleshooting guides, or assistance with specific
          issues, you’ve come to the right place.</p>
          
          <p>
            <b>How Can We Assist You Today?</b>
          </p>
          <ul>
            <li>
              <b>Search the Knowledge Base:</b>Browse our articles and guides to
              find solutions quickly.
            </li>
            <li>
              <b>Submit a Support Request:</b>Can’t find what you’re looking
              for? Create a support ticket, and our team will get back to you
              promptly.
            </li>
            <li>
              <b>Track Your Requests:</b>View the status of your existing
              support tickets.
            </li>
          </ul>
          <p>
            Our goal is to ensure you get the help you need as quickly as
            possible. We’re constantly updating our resources, so be sure to
            check back for new articles and updates.
          </p>
          <p>
            If you have any feedback on how we can improve your support
            experience, don’t hesitate to let us know!
          </p>
          <br/>
          <p>To report any technical issues or to submit inquiries, please log in to your 
          Support account at <a href='https://support.roosterinc.com'>https://support.roosterinc.com</a> 
          and "Submit a Request" or send an email to <a href='support@roosterinc.com'>support@roosterinc.com</a></p>
        </div>`;
