import type * as React from "react";
import { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid2,
	List,
	ListItemButton,
	ListItemText,
	IconButton,
} from "@mui/material";
import { usePageContext } from "../context/PageContext";
import { downloadFile, getContent } from "../services/apiServices";
import { useAuth } from "../context/AuthContext";
import { getHeaderList, processContent } from "../helpers/pageContentProcessor";
import { defaultPageContent } from "../constants/defaultPageContent";
import "./documentation.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

type Header = {
	text: string;
	level: number;
	id: string;
};

const Content: React.FC = () => {
	const { selectedPageId, setSelectedPageContent, setSelectedPageId } =
		usePageContext();
	const [pageContent, setPageContent] = useState("");
	const [pageTitle, setPageTitle] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [headers, setHeaders] = useState<Header[]>([]);
	const [selectedHeading, setSelectedHeading] = useState(0);
	const { isAuthenticated } = useAuth();
	const cardContentRef = useRef<HTMLDivElement>(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const handleClickEvents = (event: React.MouseEvent) => {
		const target = event.target as HTMLElement;
		if (target.tagName === "A" && target.hasAttribute("data-file-url")) {
			event.preventDefault();
			const target = event.target as HTMLAnchorElement;
			const fileUrl = target.getAttribute("data-file-url");
			if (fileUrl) {
				downloadFile(fileUrl);
			}
		}
		if (
			target.tagName === "A" &&
			target.hasAttribute("data-linked-resource-id")
		) {
			// TODO: Handle click event for linked pages
		}
	};

	const handleDownloadPdf = async () => {
		window.print();
	};

	useEffect(() => {
		setIsLoading(true);
		const fetchPageContent = async () => {
			if (!selectedPageId) {
				setPageTitle("Welcome to Rooster Support");
				setPageContent(defaultPageContent);
				return;
			}

			const response = await getContent(isAuthenticated, selectedPageId);
			setPageTitle(response.title);

			const formattedBody = processContent(response.body, setSelectedPageId);

			const headerList = getHeaderList(
				`<h1>${response.title}</h1>${formattedBody}`,
			);
			setHeaders(headerList);
			setPageContent(`${formattedBody}`);
			setSelectedPageContent(`${formattedBody}`);
			if (cardContentRef.current) {
				cardContentRef.current.scrollTop = 0;
			}
		};

		fetchPageContent();

		setIsLoading(false);
	}, [
		isAuthenticated,
		selectedPageId,
		setSelectedPageContent,
		setSelectedPageId,
	]);

	const handleClick = (id: string, index: number) => {
		const element = document.getElementById(id);

		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
			});
		}
		setSelectedHeading(index);
	};

	if (isLoading) {
		return <>Loading...</>;
	}

	return (
		<Grid2 container spacing={2}>
			<Grid2
				size={{ xl: 9.5, lg: 9, md: 12, sm: 12, xs: 12 }}
				sx={{
					mt: { xl: "80px", lg: "80px", sm: "60px", xs: "60px" },
					ml: { xl: 3, lg: 3, sm: 0, xs: 0 },
					"@media print": {
						mt: 0,
					},
				}}
			>
				<Card
					elevation={0}
					sx={{
						border: "1px solid #ddd",
						borderRadius: 1.2,
						"@media print": {
							width: "100vw",
						},
					}}
					id="support-content-card"
				>
					<CardHeader
						title={pageTitle}
						titleTypographyProps={{ variant: "h4" }}
						sx={{ pl: { xl: 5, xs: 2 } }}
						action={
							<IconButton
								onClick={handleDownloadPdf}
								sx={{
									"@media print": {
										display: "none",
									},
								}}
							>
								<PictureAsPdfIcon
									sx={{
										"@media print": {
											display: "none",
										},
									}}
								/>
							</IconButton>
						}
					/>

					<Divider />
					<CardContent
						sx={{
							height: { xl: "calc(100vh - 190px)", sm: "calc(100vh - 139px)" },
							overflowY: "auto",
							pl: { xl: 5, xs: 1 },
							width: {
								xl: "calc(200vh - 250px)",
								md: "calc(100vw - 240px)",
								sm: "calc(100vw - 240px)",
								xs: "100vw",
							},
							"@media print": {
								"::-webkit-scrollbar": {
									display: "none",
								},
								width: "100vw",
								height: "auto",
							},
						}}
						ref={cardContentRef}
					>
						<Box
							sx={{
								width: {
									xl: "calc(100vw - 800px)",
									lg: "calc(100vw - 400px)",
									md: "calc(100vw - 400px)",
									sm: "calc(100vw - 270px)",
									xs: "calc(100vw - 40px)",
								},
								wordWrap: "break-word",
								whiteSpace: "normal",
								overflowWrap: "break-word",
								pl: { sm: 2, xs: 2 },
								pr: {
									xs: 0,
									sm: 0,
									md: 0,
									lg: "calc(100vh - 500px)",
									xl: "calc(100vw - 1900px)",
								},
								"@media print": {
									width: "90vw",
								},
							}}
						>
							<div id="support-content" onClick={handleClickEvents}>
								{parse(pageContent)}
							</div>
						</Box>
					</CardContent>
				</Card>
			</Grid2>
			{!isMobile && (
				<Grid2
					size={{ xl: 2, lg: 2, md: 2, sm: 0 }}
					sx={{
						alignItems: "center",
						mt: "80px",
						"@media print": {
							display: "none",
						},
					}}
				>
					{headers.length === 0 ? (
						<></>
					) : (
						<Card
							sx={{
								height: "calc(100vh - 115px)",
								display: { lg: "block", xs: "none", sm: "none", md: "none" },
								border: "1px solid #ddd",
								borderRadius: 1.2,
								"@media print": {
									display: "none",
								},
							}}
							elevation={0}
						>
							<CardContent>
								<Box
									sx={{
										overflowY: "auto",
										overflowX: "hidden",
										maxHeight: "calc(100vh - 190px)",
										ml: -1,
										mt: 3,
									}}
								>
									<List>
										{headers.map((header, index) => (
											<ListItemButton
												key={index}
												sx={{
													pl: (header.level - 1) * 1.8,
													pb: 0,
													pt: 0,
													pr: 0,
													color:
														selectedHeading === index
															? "primary.main"
															: "text.primary",
													"&.Mui-selected": {
														backgroundColor: "transparent",
													},
													"&.Mui-selected:hover": {
														backgroundColor: "transparent",
													},
													"&:hover": {
														backgroundColor: "transparent",
														color: "primary.main",
													},
												}}
												onClick={() => handleClick(header.id, index)}
												selected={selectedHeading === index}
											>
												<ListItemText
													primary={header.text}
													onClick={() => handleClick(header.id, index)}
													sx={{
														fontSize: "0.875rem",
														"& .MuiTypography-root": {
															fontSize: "0.875rem",
														},
													}}
												/>
											</ListItemButton>
										))}
									</List>
								</Box>
							</CardContent>
						</Card>
					)}
				</Grid2>
			)}
		</Grid2>
	);
};

export default Content;
