import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { PageProvider } from "./context/PageContext";

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <PageProvider>
          <App />
        </PageProvider>
      </AuthProvider>
    </React.StrictMode>
  );
}
